@import 'app.scss';
.wrapper {
  --background-color: var(--color-bg-primary-brand);
  --text-color: var(--color-fg-on-primary-brand);

  row-gap: 0;
  border-radius: var(--border-radius-medium);
  background-color: var(--background-color);
  padding: spacing(8) spacing(6) spacing(14);
  color: var(--text-color);

  @include from-breakpoint(lg) {
    padding: var(--section-vertical-spacing) 0;
  }
}

.summary {
  grid-column: 1 / -1;

  @include from-breakpoint(lg) {
    &:nth-child(1) {
      grid-column: 3 / 7;
    }

    &:nth-child(2) {
      grid-column: 7 / 11;
    }
  }
}

.group {
  position: relative;
  padding: spacing(6) 0;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    background: var(--text-color);
    height: 1px;
    content: '';
  }

  @include from-breakpoint(lg) {
    grid-column: 1;

    &:nth-child(n + 5) {
      grid-column: 2;
    }
  }
}

.icon {
  transform: translateY(-3px);
}

.term {
  display: flex;
  gap: spacing(2);
  align-items: center;
  margin-bottom: spacing(2);
}

.description {
  margin-left: 0;
  white-space: pre-line;
}
